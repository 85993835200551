import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponseBase, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';
import { MessageService } from '../service/message.service';
@Injectable()
export class CustomJsonInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private msgSrv: MessageService,) { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        if (req.responseType === 'json') {
            return next.handle(req).pipe(
                timeout(3 * 60000),
                map((event) => {
                    if (event instanceof HttpResponse) {
                        //统一返回错误消息
                        if (event.body.type !== "Success") {
                            this.msgSrv.alert(event.body.data, event.body.type);
                        }
                        return event.clone({ body: event.body });
                    } else {
                        return event;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    let errorMsg: string;
                    if (error.error instanceof ErrorEvent) {
                        errorMsg = `Error: ${error.error.message}`;
                    }
                    else {
                        switch (error.status) {
                            case 401: {
                                this.goTo("/login");
                                errorMsg = `Unauthorized: 登录超时，请您重新登录。`;
                                break;
                            }
                            case 403:
                                //this.goTo("/unauthorized");
                                errorMsg = `Forbidden: 当前用户等级不允许执行该操作。`;
                                break;
                            case 404: {
                                errorMsg = `Not Found: 服务器找不到请求的网页。`;
                                break;
                            }
                            case 500: {
                                errorMsg = `Internal Server Error: 程序开小差了，请重新尝试或联系管理员处理！`;
                                break;
                            }
                            default: {
                                errorMsg = `Unknown Server Error: ${error.message}`;
                                break;
                            }
                        }
                        this.msgSrv.alert(errorMsg);
                    }
                    return throwError(errorMsg);
                })
            );
        }
        return next.handle(req);
    }

    private goTo(url: string) {
        setTimeout(() => this.injector.get(Router).navigateByUrl(url));
    }
}