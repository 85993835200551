import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ResponseType } from '../models/response.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient) { }

    get Http() {
        return this.http;
    }
    post(url: string, data: any): Observable<ResponseType> {
        if (data) {
            for (const key in data) {
                const value = data[key];
                if (Array.isArray(value)) {
                    data[key] = JSON.stringify(value);
                }
            }
        }
        return this.http.post<ResponseType>(url, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }
    get(url: string, data: any = null): Observable<ResponseType> {
        if (data) {
            for (const key in data) {
                const value = data[key];
                if (Array.isArray(value)) {
                    data[key] = JSON.stringify(value);
                }
            }
            const myparams = new HttpParams({
                fromObject: data
            });
            return this.http.get<ResponseType>(url, {
                params: myparams
            });
        }
        else
            return this.http.get<ResponseType>(url);
    }

    success(data: any) {
        let resp = new ResponseType();
        resp.ok = true;
        resp.type = 0;
        resp.data = data;
        return of(resp);
    }

    test(data: any) {
        let resp = new ResponseType();
        resp.ok = true;
        resp.type = 0;
        resp.data = data;
        return of(resp).pipe(delay(1000));
    }
}
