import { NgModule } from "@angular/core";
import { ChannelPipe, SaleChannelPipe } from "./channel.pipe";
import { CurrencyPipe } from "./currency.pipe";
import { ContrastMetricNamePipe,ContrastNamePipe, ContrastTextPipe } from "./contrast.pipe";
@NgModule({
    declarations:[
        ChannelPipe,
        CurrencyPipe,
        ContrastMetricNamePipe,
        ContrastNamePipe,
        ContrastTextPipe,
        SaleChannelPipe
    ],
    exports:[
        ChannelPipe,
        CurrencyPipe,
        ContrastMetricNamePipe,
        ContrastNamePipe,
        ContrastTextPipe,
        SaleChannelPipe
    ]
})
export class PipeModule{}