import { differenceInDays, differenceInHours, differenceInMinutes } from "date-fns";

export class Utils {
    static currency = [
        { label: '¥', value: 'CNY' },
        { label: '$', value: 'USD' },
        { label: '€', value: 'EUR' },
        { label: '£', value: 'GBP' },
        { label: 'C$', value: 'CAD' },
        { label: 'JP¥', value: 'JPY' },
        { label: 'A$', value: 'AUD' },
        { label: 'MX$', value: 'MXN' }
    ]
    static getCurrencySign(currency: string) {
        let item = this.currency.find(o => o.value === currency);
        return item?.label;
    }
    static combination(arr: any[]) {
        return arr.reduce((pre, cur) => {
            let res: any[] = [];
            pre.forEach((_pre: any[]) => {
                cur.forEach((_cur: any) => {
                    res.push(_pre.concat([_cur]));
                })
            })
            return res;
        }, [[]])
    }
    static getBase64(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    static getDisplayedTime(date: string) {
        let hours = differenceInHours(new Date, new Date(date));
        if (hours < 24) {
            if (hours < 1) {
                let minutes = differenceInMinutes(new Date, new Date(date));
                return minutes + "分钟前";
            }
            else {
                return hours + "小时前";
            }
        }
        else {
            let days = differenceInDays(new Date, new Date(date));
            return days + "天前";
        }
    }
    static getContrastName(contrast: string, metric: string): string {
        switch (contrast) {
            case 'today':
                switch (metric) {
                    case 'current':
                        return '今天';
                    case 'mom':
                        return '昨天';
                    case 'yoy':
                        return '去年今天';
                }
                break;
            case 'week':
                switch (metric) {
                    case 'current':
                        return '本周';
                    case 'mom':
                        return '上周';
                    case 'yoy':
                        return '去年同期';
                }
                break;
            case 'month':
                switch (metric) {
                    case 'current':
                        return '本月';
                    case 'mom':
                        return '上月';
                    case 'yoy':
                        return '去年同期';
                }
                break;
            case 'year':
                switch (metric) {
                    case 'current':
                        return '今年';
                    case 'mom':
                        return '去年';
                    case 'yoy':
                        return '去年';
                }
                break;
        }
        return metric + '-' + contrast;
    }

    static getSiteName(site: string): string {
        switch (site) {
            case 'US':
                return '美国';
            case 'CA':
                return '加拿大';
            case 'MX':
                return '墨西哥';
            case 'UK':
            case 'GB':
                return '英国';
            case 'FR':
                return '法国';
            case 'DE':
                return '德国';
            case 'IT':
                return '意大利';
            case 'NL':
                return '荷兰';
            case 'PL':
                return '波兰';
            case 'TR':
                return '土耳其';
            case 'JP':
                return '日本';
            case 'AU':
                return '澳大利亚';
            case 'ES':
                return '西班牙';
            case 'SE':
                return '瑞典';
            default:
                return site;
        }
    }

    static round(num: number, decimal: number) {
        if (isNaN(num)) {
            return 0;
        }
        const p1 = Math.pow(10, decimal + 1);
        const p2 = Math.pow(10, decimal);
        return Math.round(num * p1 / 10) / p2;
    }

    /**
    * 多区间判断
    * @param area
    * @param precision 精度(可用值：0, 0.1, 0.01...)
    * @returns {number}   错误类型(0正常 1区间重合 2最小值大于最大值 3区间没有连续)
    */
    static checkIfArrayIntervalOverLap(area: any[], precision: number = 0): number {
        let result = 0;
        let areaLength = area.length;
        if (areaLength > 0) {
            let maxStartArr = [];
            let minEndArr = [];
            let minStart1 = 0;
            let minStart2 = 0;
            let maxEnd1 = 0;
            let maxEnd2 = 0;
            let secondResult = false;
            let seriesNumber = 0;
            let newMaxEnd = 0;
            for (let i = 0; i < areaLength; i++) {
                minStart1 = area[i].rGWeight1;
                maxEnd1 = area[i].rGWeight2;
                secondResult = false;
                seriesNumber = 0;
                // 判断最小值是否大于最大值
                if (minStart1 > maxEnd1 && maxEnd1 !== 0) {
                    result = 2
                    break;
                }
                for (let t = 0; t < areaLength; t++) {
                    minStart2 = area[t].rGWeight1;
                    maxEnd2 = area[t].rGWeight2;
                    if (i !== t) { // 不与自身比 
                        maxStartArr = [minStart1, minStart2];// 开始课时数组
                        minEndArr = [maxEnd1, maxEnd2];// 结束课时数组
                        newMaxEnd = Number(maxEnd1 + precision);
                        // 判断数字是否连续
                        if (newMaxEnd === minStart2) {
                            seriesNumber = seriesNumber + precision;
                        }
                        // 判断是否有重合区间
                        if (Math.max(...maxStartArr) <= Math.min(...minEndArr)) {
                            secondResult = true
                            result = 1
                            break;
                        }
                    }
                }
                // 判断是否有重合区间返回结果
                if (secondResult === true) {
                    break;
                }
                // 判断区间是否连续
                if (seriesNumber !== precision && i !== areaLength - 1) {
                    result = 3
                    break;
                }
            }
        }
        return result;
    }

    static downloadCSV(filename: string, mimetype: string, content: string) {
        if ('msSaveOrOpenBlob' in navigator) {
            // Microsoft Edge and Microsoft Internet Explorer 10-11
            var blob = new Blob(["\ufeff" + content], { type: mimetype });//result为从后台返回的数据
            let navigator: any = window.navigator;
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var element = document.createElement('a');
            //在数据前面加上字符串"\ufeff"解决中文乱码的问题
            element.setAttribute('href', 'data:' + mimetype + ';charset=utf-8,' + encodeURIComponent("\ufeff" + content));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }


}

