import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CacheUtil } from 'src/app/util/cache.util';
import { HomeLayoutService } from './home.layout.service';
@Component({
    selector: 'app-home',
    templateUrl: './home.layout.component.html',
    styleUrls: ['./home.layout.component.less'],
    providers: [HomeLayoutService]
})

export class HomeLayoutComponent implements OnInit {
    isCollapsed: boolean = false;
    user: any = null;
    menus: any[] = [];
    topMenu: any = null;
    constructor(private router: Router, private srv: HomeLayoutService) { }

    ngOnInit() {
        this.getUserMenus();
        this.getUserInfo();
    }

    getUserMenus() {
        this.srv.getUserMenus().subscribe((resp) => {
            if (resp.ok) {
                this.menus = resp.data;
                if (Array.isArray(this.menus) && this.menus.length > 0) {
                    this.menus.forEach((menu) => {
                        if (menu.route !== "appstore") {
                            menu.subMenus.unshift({
                                icon: "home",
                                name: "首页",
                                route: "dashboard",
                                subMenus: []
                            });
                        }
                    });
                    let hashArr = location.hash.split("/");
                    if (Array.isArray(hashArr) && hashArr.length > 2) {
                        let topMenuRoute = hashArr[1];
                        this.topMenu = this.menus.find((o: any) => o.route === topMenuRoute);
                    }
                }
            }
        });
    }

    getUserInfo() {
        this.srv.getUserInfo().subscribe((resp) => {
            if (resp.ok) {
                this.user = resp.data;
                CacheUtil.set("user", this.user);
            }
        });
    }

    changeMenu(_topMenu: any) {
        this.topMenu = _topMenu;
    }

    toDashboard() {
        this.topMenu = null;
    }

    exit() {
        localStorage.removeItem('token');
        this.router.navigateByUrl('/login');
    }
}