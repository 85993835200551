import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/home' },
    { path: 'home', loadChildren: () => import('./layout/home/home.layout.module').then(m => m.HomeLayoutModule) },
    { path: 'login', loadChildren: () => import('./layout/login/login.layout.module').then(m => m.LoginLayoutModule) },
    { path: 'supports', loadChildren: () => import('./layout/supports/supports.layout.module').then(m => m.SupportsLayoutModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
