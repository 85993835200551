export const environment = {
    production: true,
    version: '1.0.2',
    host: 'http://sandbox-tuna-api.99labs.net/',
    //host: 'https://localhost:44366/',//http://sandbox-tuna-api.99labs.net/',//
    aes: {
        cs: {
            key: '%Tuna210623inQc$',
            iv: '$CqIN326012ANUt%'
        }
    }
};