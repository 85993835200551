import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyPipe' })
export class CurrencyPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case "USD":
                return "$";
            case "AUD":
                return "A$";
            case "CAD":
                return "C$";
            case "EUR":
                return "€";
            case "JPY":
                return "JP¥";
            case "GBP":
                return "£";
            case "RUB":
                return "₽";
            case "CNY":
                return "¥";
            case "HKD":
                return "HK$";
            case "MXN":
                return "MX$";
            default:
                return value;
        }
    }
}