import { NgModule } from '@angular/core';
import { LoginLayoutModule } from './login/login.layout.module';
import { HomeLayoutModule } from './home/home.layout.module';

@NgModule({
    imports: [
        HomeLayoutModule,
        LoginLayoutModule
    ],
    exports: [
        LoginLayoutModule,
        HomeLayoutModule
    ]
})
export class LayoutModule { }