export class PathUtil {
    public static verify: string = 'oauth/verify';
    public static upload: string = 'upload/uploadFile';
    //shared
    public static getImages: string = 'share/getImages';
    public static getBrands: string = 'share/getBrands';
    public static getWarehouses: string = 'share/getWarehouses';
    public static getTodoItems: string = 'share/getTodoItems';
    public static getCountries: string = "share/getCountries";
    public static getCurrencies: string = "share/getCurrencys";
    public static getMyAmzAccountSite: string = 'share/getmyamzaccountsite';
    public static getPlatforms: string = "share/getPlatforms";
    public static getGoodsTypes: string = "share/getGoodsTypes";
    public static getAccounts: string = "share/getAccounts";
    public static getCarriers: string = 'share/getCarriers';
    public static getCarrierTypes: string = 'share/getCarrierTypes';
    public static getUsersByRole: string = 'share/getUsersByRole';
    public static getGlobalStoreNames: string = 'share/getGlobalStoreNames';
    public static getAccountsAndSites: string = 'share/getAccountsAndSites';
    //sys
    public static sys_lwa_getoauthuri: string = 'lwa/getoauthuri';
    public static sys_menu_getusermenus: string = 'sys/menu/getusermenus';
    public static sys_menu_getauthrequiredmenusfortreeselect: string = 'sys/menu/getauthrequiredmenusfortreeselect';
    public static sys_amzaccount_getusedamzglobalnameswithregions: string = 'sys/amzaccount/getusedamzglobalnameswithregions';
    public static sys_amzaccount_getbankaccounts: string = 'sys/amzaccount/getbankaccounts';
    public static sys_amzaccount_getcompanies: string = 'sys/amzaccount/getcompanies';
    public static sys_amzaccount_getcreditcards: string = 'sys/amzaccount/getcreditcards';
    public static sys_amzaccount_getamzregionssites: string = 'sys/amzaccount/getamzregionsites';
    public static sys_amzaccount_listamzaccounts: string = 'sys/amzaccount/listamzaccounts';
    public static sys_amzaccount_addamzaccount: string = 'sys/amzaccount/addamzaccount';
    public static sys_amzaccount_updateamzaccount: string = 'sys/amzaccount/updateamzaccount';
    public static sys_amzaccount_enablesite: string = 'sys/amzaccount/enablesite';
    public static sys_amzaccount_getamzauthstatus: string = 'sys/amzaccount/getamzauthstatus';
    public static sys_amzaccount_getamzglobalnames: string = 'sys/amzaccount/getamzglobalnames';
    public static sys_amzaccount_getamzregions: string = 'sys/amzaccount/getamzregions';
    public static sys_amzaccount_getamzsites: string = 'sys/amzaccount/getamzsites';
    public static sys_user_getCurrentUserInfo: string = 'sys/user/getCurrentUserInfo';
    public static sys_user_getUsers: string = 'sys/user/getUsers';
    public static sys_user_searchusers: string = "sys/user/searchusers";
    public static sys_user_getlevels: string = "sys/user/getlevels";
    public static sys_user_addorupdateuser: string = "sys/user/addorupdateuser";
    public static sys_user_userquit: string = "sys/user/userquit";
    public static sys_role_getroles: string = "sys/role/getroles";
    public static sys_role_getrolefors: string = "sys/role/getrolefors";
    public static sys_role_addorupdaterole: string = "sys/role/addorupdaterole";
    public static sys_role_deleterole: string = "sys/role/deleterole";
    public static sys_role_searchroles: string = "sys/role/searchroles";
    public static sys_brand_searchbrand: string = "sys/brand/searchbrand";
    public static sys_brand_addorupdatebrand: string = "sys/brand/addorupdatebrand";
    public static sys_brand_deletebrand: string = "sys/brand/deletebrand";
    public static sys_user_getallusersforselectctrl: string = "sys/user/getallusersforselectctrl";
    public static sys_holder_searchamzaccountsholders: string = "sys/holder/searchamzaccountsholders";
    public static sys_holder_searchscmholders: string = "sys/holder/searchscmholders";
    public static sys_holder_searchprdholders: string = "sys/holder/searchprdholders";
    public static sys_holder_updateamzaccountholders: string = "sys/holder/updateamzaccountholders";
    public static sys_holder_batchupdateamzaccountholders: string = "sys/holder/batchupdateamzaccountholders";
    public static sys_holder_updatescmholders: string = "sys/holder/updatescmholders";
    public static sys_holder_updateprdholders: string = "sys/holder/updateprdholders";
    public static sys_admin_tasks_state: string = "task/state";
    public static sys_admin_tasks_run: string = "task/run";
    public static sys_ecs_search: string = "sys/ecs/search";
    public static sys_ecs_addorupdate: string = "sys/ecs/addorupdate";
    public static sys_ecs_delete: string = "sys/ecs/delete";
    //pss
    //product
    public static pss_product_getCategories: string = 'pss/product/getCategories';
    public static pss_product_getProducts: string = 'pss/product/getProducts';
    public static pss_product_addProduct: string = 'pss/product/addProduct';
    public static pss_product_getSPUByCode: string = 'pss/product/getSPUByCode';
    public static pss_product_updateProduct: string = 'pss/product/updateProduct';
    public static pss_product_searchspus: string = "pss/product/searchspus";
    public static pss_product_getDeclaredInfos: string = "pss/product/getDeclaredInfos";
    public static pss_product_getProductCodes: string = "pss/product/getProductCodes";
    public static pss_product_saveDeclaredInfo: string = "pss/product/saveDeclaredInfo";
    public static pss_product_getSPUDeclaredInfoByCode: string = "pss/product/getSPUDeclaredInfoByCode";
    //category
    public static pss_category_getCategories: string = 'pss/category/getCategories';
    public static pss_category_addCategory: string = 'pss/category/addCategory';
    public static pss_category_updateCategory: string = 'pss/category/updateCategory';
    public static pss_category_removeCategory: string = 'pss/category/removeCategory';
    public static pss_category_getCategoryByLevel: string = 'pss/category/getCategoryByLevel';
    public static pss_category_getcategoriesfortreeselect: string = 'pss/category/getcategoriesfortreeselect';

    //sss
    public static sss_order_getOrders: string = 'sss/order/getOrders';
    public static sss_order_getOrder: string = 'sss/order/getOrder';
    public static sss_order_getAuditOrders: string = 'sss/order/getAuditOrders';
    public static sss_order_getPendingOrders: string = 'sss/order/getPendingOrders';
    public static sss_order_getAmzOrderStatuses: string = 'sss/order/getAmzOrderStatuses';
    public static sss_order_getAmzOrderFundsStatuses: string = 'sss/order/getAmzOrderFundsStatuses';
    public static sss_order_getAmzAccounts: string = 'sss/order/getAmzAccounts';
    public static sss_order_getSaleChannels: string = 'sss/order/getSaleChannels';
    public static sss_order_getAmzSaleChannels: string = 'sss/order/getAmzSaleChannels';
    public static sss_order_updateOrderAddr: string = 'sss/order/updateOrderAddr';
    public static sss_order_orderSpuMapping: string = 'sss/order/orderSpuMapping';
    public static sss_order_getSPUInfoByCode: string = "sss/order/getSPUInfoByCode";
    public static sss_order_getDeliveryMethods: string = "sss/order/getDeliveryMethods";
    public static sss_order_getBrands: string = "sss/order/getBrands";
    public static sss_order_changeHolder: string = "sss/order/changeHolder";
    public static sss_order_getHolders: string = "sss/order/getHolders";
    public static sss_order_getOrderProfit: string = "sss/order/getOrderProfit";
    public static sss_order_cancelOrder: string = "sss/order/cancelOrder";
    public static sss_order_toggleSuspendOrder: string = "sss/order/toggleSuspendOrder";
    public static sss_order_getInventoryBatchInfoByCode: string = "sss/order/getInventoryBatchInfoByCode";
    public static sss_order_getGoodsPartBySpu: string = "sss/order/getGoodsPartBySpu";
    public static sss_order_getKCInfoByCode: string = "sss/order/getKCInfoByCode";
    public static sss_order_reSendOrder: string = "sss/order/reSendOrder";
    public static sss_order_replacementGoodsPart: string = "sss/order/replacementGoodsPart";
    public static sss_order_getBuyerInfoById: string = "sss/order/getBuyerInfoById";
    public static sss_order_orderRefund: string = "sss/order/orderRefund";
    public static sss_order_mergeOrder: string = "sss/order/mergeOrder";
    public static sss_order_splitOrder: string = "sss/order/splitOrder";
    public static sss_order_getOrderDetailsByKey: string = "sss/order/getOrderDetailsByKey";

    public static sss_interceptrule_getActions: string = "sss/interceptrule/GetActions";
    public static sss_interceptrule_getItems: string = "sss/interceptrule/GetItems";
    public static sss_interceptrule_getAccounts: string = "sss/interceptrule/GetAccounts";
    public static sss_interceptrule_getStatuses: string = "sss/interceptrule/GetStatuses";
    public static sss_interceptrule_getTargets: string = "sss/interceptrule/GetTargets";
    public static sss_interceptrule_getRules: string = "sss/interceptrule/GetRules";
    public static sss_interceptrule_getRulesByTarget: string = "sss/interceptrule/GetRulesByTarget";
    public static sss_interceptrule_addOrUpdateRule: string = "sss/interceptrule/AddOrUpdateRule";
    public static sss_interceptrule_changeRuleStatus: string = "sss/interceptrule/ChangeRuleStatus";
    public static sss_interceptrule_deleteRule: string = "sss/interceptrule/DeleteRule";
    public static sss_interceptrule_getRuleById: string = "sss/interceptrule/GetRuleById";
    public static sss_interceptrule_testRule: string = "sss/interceptrule/testRule";

    //ass
    public static ass_case_getCases: string = "sss/afterSale/getCases";
    public static ass_case_closingCase: string = 'sss/afterSale/closingCase';
    public static ass_case_getCaseTypes: string = "sss/afterSale/getCaseTypes";
    public static ass_case_getCaseStatuses: string = "sss/afterSale/getCaseStatuses";
    public static ass_case_addCase: string = 'sss/afterSale/addCase';
    public static ass_tool_orderFinder: string = "sss/afterSale/getOrders";

    public static ass_afterSale_importGenericReport: string = "ass/afterSale/importGenericReport";
    public static ass_afterSale_getDatasFrom: string = "ass/afterSale/getDatasFrom";
    public static ass_afterSale_getAfterSaleGenreReasons: string = "ass/afterSale/getAfterSaleGenreReasons";
    public static ass_afterSale_getAfterSaleList: string = "ass/afterSale/getAfterSaleList";
    public static ass_afterSale_exportAfterSaleList: string = "ass/afterSale/exportAfterSaleList";
    public static ass_afterSale_getAfterSaleActions: string = "ass/afterSale/getAfterSaleActions";
    public static ass_afterSale_getCompensationSubjects: string = "ass/afterSale/getCompensationSubjects";

    //spumapping
    public static sss_spumapping_addspumapping: string = 'sss/spumapping/addspumapping';
    public static sss_spumapping_updatespumapping: string = 'sss/spumapping/updatespumapping';
    public static sss_spumapping_removespumapping: string = 'sss/spumapping/removespumapping';
    public static sss_spumapping_searchspumappings: string = 'sss/spumapping/searchspumappings';
    public static sss_spumapping_batchaddspumapping: string = 'sss/spumapping/batchaddspumappings';
    //listing
    public static sss_listing_getListings: string = 'sss/listing/getListings';
    public static sss_listing_getSitesAndAccounts: string = 'sss/listing/getSitesAndAccounts';
    public static sss_listing_getListingStatus: string = 'sss/listing/getListingStatus';
    public static sss_listing_getListingById: string = 'sss/listing/getListingById';
    public static sss_listing_setListingUserHolder: string = 'sss/listing/SetListingUserHolder';
    //boss
    public static boss_report_sales_account: string = 'boss/sales/account';
    public static boss_report_sales_store: string = 'boss/sales/store';
    public static boss_report_sales_product: string = 'boss/sales/product';
    public static boss_report_sales_asin: string = 'boss/sales/asin';
    public static boss_report_sales_brand: string = 'boss/sales/brand';
    public static boss_report_sales_team: string = 'boss/sales/team';
    public static boss_report_profits_account: string = 'boss/profits/account';
    public static boss_report_profits_store: string = 'boss/profits/store';
    public static boss_report_profits_product: string = 'boss/profits/product';
    public static boss_report_profits_asin: string = 'boss/profits/asin';
    public static boss_report_profits_brand: string = 'boss/profits/brand';
    public static boss_report_profits_team: string = 'boss/profits/team';
    public static boss_report_trends_contrastsales: string = 'boss/trends/contrastsales';
    public static boss_marketing_importAds: string = 'boss/marketing/importAds';
    public static boss_marketing_getAds: string = 'boss/marketing/getAds';
    public static boss_finance_bonus: string = 'boss/finance/bonus';
    public static boss_finance_salesSummary: string = 'boss/finance/salesSummary';
    public static boss_finance_exportBonus: string = 'boss/finance/exportBonus';
    public static boss_profits_account: string = 'boss/profits/account';
    public static boss_profits_store: string = 'boss/profits/store';
    public static boss_profits_product: string = 'boss/profits/product';
    public static boss_profits_asin: string = 'boss/profits/asin';
    public static boss_profits_brand: string = 'boss/profits/brand';
    public static boss_profits_team: string = 'boss/profits/team';

    //app
    public static app_fbafee_searchsitesforfbafee: string = 'app/fba/searchsitesforfbafee';
    public static app_fbafee_calcfbapostfee: string = 'app/fba/calcfbapostfee';

    //lss
    public static lss_basic_getCarrierForList: string = 'lss/basic/getCarrierForList';
    public static lss_basic_updateCarrier: string = 'lss/basic/updateCarrier';
    public static lss_basic_updateCarrierway: string = 'lss/basic/updateCarrierway';
    public static lss_basic_getCarriers: string = 'lss/basic/getCarriers';
    public static lss_basic_getCarrierWays: string = 'lss/basic/getCarrierWays';
    public static lss_basic_changeCarrierStatus: string = 'lss/basic/changeCarrierStatus';
    public static lss_basic_changeCarrierWayStatus: string = 'lss/basic/changeCarrierWayStatus';
    public static lss_basic_addOrUpdateQuotation: string = 'lss/basic/addOrUpdateQuotation';
    public static lss_basic_deleteQuotation: string = 'lss/basic/deleteQuotation';
    public static lss_basic_getQuotationModes: string = 'lss/basic/getQuotationModes';
    public static lss_basic_getDeclaredrules: string = 'lss/basic/getDeclaredrules';
    public static lss_basic_addOrUpdateDeclaredrule: string = 'lss/basic/addOrUpdateDeclaredrule';
    public static lss_basic_deleteDeclaredrule: string = 'lss/basic/deleteDeclaredrule';
    public static lss_basic_getDeclareModes: string = 'lss/basic/getDeclareModes';
    public static lss_basic_getQuotations: string = 'lss/basic/getQuotations';
    public static lss_basic_getLogisticsRules: string = 'lss/basic/getLogisticsRules';
    public static lss_basic_ruleOrderToTop: string = 'lss/basic/ruleOrderToTop';
    public static lss_basic_deleteRule: string = 'lss/basic/deleteRule';
    public static lss_basic_changeOrder: string = 'lss/basic/changeOrder';
    public static lss_basic_ruleTestQueryOrderInfoByOrderNo: string = 'lss/basic/ruleTestQueryOrderInfoByOrderNo';
    public static lss_basic_ruleTestMatchCarrierByOrderNo: string = 'lss/basic/ruleTestMatchCarrierByOrderNo';
    public static lss_basic_addOrUpdateRule: string = 'lss/basic/addOrUpdateRule';
    public static lss_basic_getRuleById: string = 'lss/basic/getRuleById';
    public static lss_basic_getRuleInfosByCarrierWay: string = 'lss/basic/getRuleInfosByCarrierWay';
    public static lss_basic_applyCarrierWay: string = 'lss/basic/applyCarrierWay';

    public static lss_logisticssoa_uploadsoa: string = "lss/logisticssoa/uploadsoa";
    public static lss_logisticssoa_submitsoa: string = "lss/logisticssoa/submitsoa";
    public static lss_logisticssoa_loadsoadatas: string = 'lss/logisticssoa/loadsoadatas';

    public static lss_directshipping_getShippingOrders: string = 'lss/directshipping/getShippingOrders';
    public static lss_directshipping_getAbnormalShippingOrders: string = 'lss/directshipping/GetAbnormalShippingOrders';
    public static lss_directshipping_getShippingOrderStatuses: string = 'lss/directshipping/getShippingOrderStatuses';
    public static lss_directshipping_getUnShippedShippingOrders: string = 'lss/directshipping/getUnShippedShippingOrders';
    public static lss_directshipping_updateDeclareInfo: string = 'lss/directshipping/updateDeclareInfo';
    public static lss_directshipping_updateCarrierInfo: string = 'lss/directshipping/updateCarrierInfo';
    public static lss_directshipping_applyTrackingNumber: string = 'lss/directshipping/applyTrackingNumber';
    public static lss_directshipping_addRemark: string = 'lss/directshipping/addRemark';
    public static lss_directshipping_hangUp: string = 'lss/directshipping/hangUp';
    public static lss_directshipping_genPickUpOrder: string = 'lss/directshipping/genPickUpOrder';
    public static lss_directshipping_getShippingTodoItems: string = 'lss/directshipping/GetShippingTodoItems';
    public static lss_directshipping_reDownloadLabel: string = 'lss/directshipping/reDownloadLabel';
    public static lss_directshipping_setOrder2Audit: string = 'lss/directshipping/setOrder2Audit';
    public static lss_directshipping_timing: string = "lss/directshipping/timing";
    public static lss_directshipping_getProblemParcels: string = "lss/directshipping/getProblemParcels";
    public static lss_directshipping_setParcelProcessStatus: string = 'lss/directshipping/setParcelProcessStatus';

    //wss
    public static wss_inventory_getInventories: string = 'wss/inventory/getInventories';
    public static wss_inventory_getInventoryBatch: string = 'wss/inventory/getInventoryBatch';
    public static wss_inventory_exportInventories: string = 'wss/inventory/exportInventories';

}