import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { httpInterceptorProviders } from './http-interceptors';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';

registerLocaleData(zh);

const ngZorroConfig: NzConfig = {
    message: { nzMaxStack: 1 }
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        LayoutModule,

    ],
    providers: [
        { provide: NZ_I18N, useValue: zh_CN },
        { provide: NZ_CONFIG, useValue: ngZorroConfig },
        httpInterceptorProviders,
        NzMessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
