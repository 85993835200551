import { Pipe, PipeTransform } from "@angular/core";
import { Utils } from "src/app/util/util";

@Pipe({ name: 'contrast_metric_name' })
export class ContrastMetricNamePipe implements PipeTransform {
    transform(metric: string): string {
        switch (metric) {
            case 'mom':
                return '环比';
            case 'yoy':
                return '同比';
            default:
                return metric;
        }
    }
}

@Pipe({ name: 'contrast_name' })
export class ContrastNamePipe implements PipeTransform {
    transform(contrast: string, metric: string): string {
        return Utils.getContrastName(contrast, metric);
    }
}

@Pipe({ name: 'contrast_text' })
export class ContrastTextPipe implements PipeTransform {
    transform(value: string) {
        switch (value) {
            case 'today':
                return '今天';
            case 'yestoday':
                return '昨天';
            case 'week':
                return '本周';
            case 'month':
                return '本月';
            case 'premonth':
                return '上月';
            case 'quarter':
                return '本季';
            case 'year':
                return '本年';
            case 'custom':
                return '自定义';
            case '3d':
                return '最近3天';
            case '7d':
                return '最近7天';
            case '14d':
                return '最近14天';
            case '30d':
                return '最近30天';
            case '60d':
                return '最近60天';
            case '90d':
                return '最近90天';
            case '180d':
                return '最近180天';
            default:
                return value;
        }
    }
}