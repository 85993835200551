import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { PathUtil } from '../../util/path.util';
import { userInfo } from '../../../assets/mocks/data';

@Injectable()
export class HomeLayoutService {
    constructor(private http: HttpService) {

    }
    getUserMenus() {
        return this.http.get(PathUtil.sys_menu_getusermenus);
    }
    getUserInfo() {
        return this.http.get(PathUtil.sys_user_getCurrentUserInfo);
    }
}