import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/service/auth-guard.service';
import { HomeLayoutComponent } from './home.layout.component';

const routes: Routes = [
    {
        path: '',
        component: HomeLayoutComponent,
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
            { path: 'dashboard', loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'sss', loadChildren: () => import('../../pages/sss/sss.module').then(m => m.SSSModule) },
            { path: 'ass', loadChildren: () => import('../../pages/ass/ass.module').then(m => m.ASSModule) },
            { path: 'pss', loadChildren: () => import('../../pages/pss/pss.module').then(m => m.PSSModule) },
            { path: 'scm', loadChildren: () => import('../../pages/scm/scm.module').then(m => m.ScmModule) },
            { path: 'lss', loadChildren: () => import('../../pages/lss/lss.module').then(m => m.LssModule) },
            { path: 'wss', loadChildren: () => import('../../pages/wss/wss.module').then(m => m.WssModule) },
            { path: 'sys', loadChildren: () => import('../../pages/sys/sys.module').then(m => m.SYSModule) },
            { path: 'boss', loadChildren: () => import('../../pages/boss/boss.module').then(m => m.BossModule) },
            { path: 'appstore', loadChildren: () => import('../../pages/appstore/appstore.module').then(m => m.AppStoreModule) },
            //{ path: '**', component: 404Component }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule { }