import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'src/app/util/util';

@Pipe({ name: 'channelPipe' })
export class ChannelPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case "Amazon.com":
                return "us";
            case "Amazon.ca":
                return "ca";
            case "Amazon.co.uk":
                return "uk";
            case "Amazon.co.jp":
                return "jp";
            default:
                return value;
        }
    }
}

@Pipe({name:'salechannel'})
export class SaleChannelPipe implements PipeTransform{
    transform(site:string):string {
        return Utils.getSiteName(site);
    }
}