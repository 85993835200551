import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthUtil } from '../../util/auth.util';
import { LoginService } from './login.layout.service';
import { AESUtil } from '../../util/aes.util';

@Component({
    selector: 'app-login',
    templateUrl: './login.layout.component.html',
    styleUrls: ['./login.layout.component.less'],
    providers: [LoginService]
})
export class LoginLayoutComponent implements OnInit {
    validateForm!: UntypedFormGroup;
    isLoading: boolean = false;
    constructor(private fb: UntypedFormBuilder, private router: Router, private srv: LoginService) { }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            userName: [null, [Validators.required]],
            password: [null, [Validators.required]]
        });
    }

    submitForm(): void {
        for (const i in this.validateForm.controls) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (this.validateForm.valid) {
            let formData = this.validateForm.value;
            this.isLoading = true;
            this.srv.login({
                user: formData.userName,
                pwd: AESUtil.Encrypt(formData.password)
            }).subscribe((resp) => {
                if (resp.ok) {
                    let token = AESUtil.Descrypt(resp.data);
                    AuthUtil.setToken(token);
                    this.router.navigateByUrl('/dashboard');
                }
            }).add(() => {
                this.isLoading = false;
            });
        }
    }
}
