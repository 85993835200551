import { NgModule } from "@angular/core";
import { LoginLayoutComponent } from './login.layout.component';
import { SharedModule } from "src/app/shared/shared.module";
import { LoginRoutingModule } from "./login-routing.module";
@NgModule({
    declarations: [LoginLayoutComponent],
    imports: [
        SharedModule,
        LoginRoutingModule,
    ],
    exports: [LoginLayoutComponent]
})
export class LoginLayoutModule { }