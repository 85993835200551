import { NgModule } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { HomeRoutingModule } from './home-routing.module';
import { HomeLayoutComponent } from './home.layout.component';
import { SharedModule } from '../../../app/shared/shared.module';

@NgModule({
    declarations: [HomeLayoutComponent],
    imports: [
        NzLayoutModule,
        NzMenuModule,
        NzAvatarModule,
        HomeRoutingModule,
        SharedModule
    ],
    exports: [HomeLayoutComponent]
})
export class HomeLayoutModule { }
